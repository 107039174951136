<template>
    <SuppliersPage
        banner_location="communications_home-top"
        supplier_router="EndUserCommunicationSupplier"
        product_router="EndUserCommunicationProduct"
        page_title="prepaid"
    ></SuppliersPage>
</template>
  
<script>
    import SuppliersPage from '@/components/endUser/suppliersPages/SuppliersPage.vue';

    export default {
        name: 'Communication',
        components: {
            SuppliersPage
        },
    }
</script>
